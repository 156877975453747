<template>
	<el-drawer :append-to-body="true" :close-on-press-escape="false" :wrapperClosable="false" title="批复环节落实"
		:visible.sync="drawer_" direction="rtl" custom-class="demo-drawer" ref="drawer" size="70%" @open="open" @close="() => {
			$refs['stateRuleForm'].resetFields();

			Object.assign($data, $options.data());

			$emit('stateVisible');
		}
			">
		<div class="demo-drawer__content" style="width: 100%">
			<div class="edit" style="padding: 0 30px; width: 100%">
				<el-form :model="ruleForm" status-icon ref="stateRuleForm" class="demo-ruleForm" label-position="rigth"
					label-width="100px">
					<div class="read-box read" v-for="(item, index) in indexListObj" :key="index">
						<div class="sy-card-header"><i></i>{{ item.name }}</div>
						<div class="read-text">
							<div v-if="item.name != '银行放款'" style="
									display: flex;
									justify-content: space-between;
									width: 70%;
								">
								<span>开始时间: {{ item.startTime }}</span>
								<span>结束时间: {{ item.endTime }}</span>
							</div>
							<div v-else style="
									display: flex;
									justify-content: space-between;
									width: 70%;
								">
								<span>放款时间: {{ item.startTime }}</span>
								<span>放款金额: {{ item.money }}</span>
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;备注:
								{{ item.remark }}
							</div>
							<div>
								&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;附件:<el-upload disabled :headers="{
									token: $store.state.userInfo.token,
								}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=product`"
									:on-preview="handelOnChange" multiple :limit="3" :file-list="item.file">
								</el-upload>
							</div>
						</div>
					</div>
					<div class="read-box" v-if="params.nextApprovalLink > 0">
						<div class="sy-card-header">
							<i></i>{{ params.nextApprovalLinkName }}
						</div>
						<div class="read-text">
							<div style="
									display: flex;
									justify-content: space-between;
									padding-top: 25px;
								">
								<el-form-item v-if="params.nextApprovalLink != 7" :rules="[
									{
										required: true,
										message: '请输入开始时间',
									},
								]" prop="startTime" label="开始时间:">
									<el-date-picker type="date" placeholder="请选择开始时间" v-model="ruleForm.startTime" style="width: 100%"
										value-format="yyyy-MM-dd"></el-date-picker>
								</el-form-item>
								<el-form-item v-else :rules="[
									{
										required: true,
										message: '请输入放款时间',
									},
								]" prop="startTime" label="放款时间:">
									<el-date-picker type="datetime" placeholder="请选择开始时间" v-model="ruleForm.startTime" style="width: 100%"
										value-format="yyyy-MM-dd HH:mm"></el-date-picker>
								</el-form-item>

								<el-form-item :rules="[
									{
										required: true,
										message: '请输入结束时间',
									},
								]" prop="endTime" v-if="params.nextApprovalLink != 7" label="结束时间:">
									<el-date-picker type="date" placeholder="请选择结束时间" v-model="ruleForm.endTime" style="width: 100%"
										value-format="yyyy-MM-dd"></el-date-picker>
								</el-form-item>
								<el-form-item :rules="[
									{
										required: true,
										message: '请输入放款金额',
									},
								]" prop="money" v-else label="放款金额:">
									<el-input onkeyup="this.value=this.value.match(/\d+\.?\d{0,5}/);this.dispatchEvent(new Event('input'))"
										placeholder="请输入金额" v-model="ruleForm.money"></el-input>
								</el-form-item>
							</div>
							<el-form-item :rules="[
								{
									required: true,
									message: '请输入备注',
								},
							]" prop="remark" label="备注:">
								<el-input type="textarea" :autosize="{ minRows: 2, maxRows: 14 }" placeholder="请输入内容"
									v-model="ruleForm.remark"></el-input>
							</el-form-item>
							<el-form-item label="附件:">
								<el-upload :headers="{
									token: $store.state.userInfo.token,
								}" class="upload-demo" name="files" :action="`${$baseUrl}/api/file/uploadFiles.do?fileType=schemeApproval`"
									:on-success="(file, fileList) =>
										handleChange(file, fileList, 'file')
										" :on-remove="(file, fileList, index) =>
		handleRemove(
			file,
			fileList,
			index,
			'file'
		)
		" multiple :limit="3" :file-list="ruleForm.file">
									<el-button size="small" type="primary">点击上传</el-button>
								</el-upload>
							</el-form-item>
						</div>
					</div>
				</el-form>

				<div class="demo-drawer__footer">
					<el-button v-if="params.nextApprovalLink > 0" @click="postschemeApprovalLinkChange"
						type="primary">保存</el-button>

					<el-button @click="$emit('closeVisible')">关 闭</el-button>
				</div>
			</div>
		</div>
	</el-drawer>
</template>

<script>
import {
	schemeApprovalLinkChange,
	readSchemeApprovalInfo,
} from '@/api/index.js';
export default {
	name: 'stateDrawer',
	props: {
		stateVisible: {
			type: Boolean,
			required: true,
		},
		params: {
			type: Object,
			required: true,
		},
		stateList: {
			type: Array,
			required: true,
		},
	},
	data() {
		return {
			index: '',
			indexList: [],
			indexNum: [],
			indexListObj: [],
			fileList: [],
			ruleForm: {
				startTime: '',
				endTime: '',
				money: '',
				remark: '',
				file: [],
			},
		};
	},
	methods: {
		handleRemove(file, fileList, index, name) {
			this.ruleForm[name].splice(index, 1);
		},
		handleChange(file, fileList, name) {
			this.ruleForm[name].push({
				name: fileList.response.list[0].fileName,
				url: fileList.response.list[0].filePath,
			});
		},
		paramsToFormData(obj) {
			const formData = new FormData();
			Object.keys(obj).forEach((key) => {
				if (obj[key] instanceof Array) {
					obj[key].forEach((item) => {
						formData.append(key, item);
					});
					return;
				}
				formData.append(key, obj[key]);
			});
			return formData;
		},
		postschemeApprovalLinkChange() {
			const file = JSON.stringify(
				this.ruleForm.file.map((item) => ({
					fileName: item.name,
					filePath: item.url,
				}))
			);
			const stateObj = {
				授信及担保协议: {
					schemeApprovalId: this.params.schemeApprovalId,
					startTimeAgreement: this.ruleForm.startTime,
					endTimeAgreement: this.ruleForm.endTime,
					remarkAgreement: this.ruleForm.remark,
					isImplementAgreement: '1',
					currentApprovalLinkName: this.params.nextApprovalLinkName,
					currentApprovalLink: this.params.nextApprovalLink,
					attachmentAgreement: file,
				},
				过桥业务: {
					schemeApprovalId: this.params.schemeApprovalId,
					startTimeBridgeBusiness: this.ruleForm.startTime,
					endTimeBridgeBusiness: this.ruleForm.endTime,
					remarkBridgeBusiness: this.ruleForm.remark,
					isImplementBridgeBusiness: '1',
					currentApprovalLinkName: this.params.nextApprovalLinkName,
					currentApprovalLink: this.params.nextApprovalLink,
					attachmentBridgeBusiness: file,
				},
				赎楼业务: {
					schemeApprovalId: this.params.schemeApprovalId,
					startTimeRedemptionBusiness: this.ruleForm.startTime,
					endTimeRedemptionBusiness: this.ruleForm.endTime,
					remarkRedemptionBusiness: this.ruleForm.remark,
					isImplementRedemptionBusiness: '1',
					currentApprovalLinkName: this.params.nextApprovalLinkName,
					currentApprovalLink: this.params.nextApprovalLink,
					attachmentRedemptionBusiness: file,
				},
				抵押登记: {
					schemeApprovalId: this.params.schemeApprovalId,
					startTimeMortgageRegistration: this.ruleForm.startTime,
					endTimeMortgageRegistration: this.ruleForm.endTime,
					remarkMortgageRegistration: this.ruleForm.remark,
					isImplementMortgageRegistration: '1',
					currentApprovalLinkName: this.params.nextApprovalLinkName,
					currentApprovalLink: this.params.nextApprovalLink,
					attachmentMortgageRegistration: file,
				},
				质押登记: {
					schemeApprovalId: this.params.schemeApprovalId,
					startTimePledgeRegistration: this.ruleForm.startTime,
					endTimePledgeRegistration: this.ruleForm.endTime,
					remarkPledgeRegistration: this.ruleForm.remark,
					isImplementPledgeRegistration: '1',
					currentApprovalLinkName: this.params.nextApprovalLinkName,
					currentApprovalLink: this.params.nextApprovalLink,
					attachmentPledgeRegistration: file,
				},
				其他业务: {
					schemeApprovalId: this.params.schemeApprovalId,
					startTimeOthers: this.ruleForm.startTime,
					endTimeOthers: this.ruleForm.endTime,
					remarkOthers: this.ruleForm.remark,
					isImplementOthers: '1',
					currentApprovalLinkName: this.params.nextApprovalLinkName,
					currentApprovalLink: this.params.nextApprovalLink,
					attachmentOthers: file,
				},
				银行放款: {
					schemeApprovalId: this.params.schemeApprovalId,
					loanTimeBankLending: this.ruleForm.startTime,
					loanAmountBankLending: this.ruleForm.money,
					remarkBankLending: this.ruleForm.remark,
					isImplementPledgeRegistration: '1',
					currentApprovalLinkName: this.params.nextApprovalLinkName,
					currentApprovalLink: this.params.nextApprovalLink,
					attachmentBankLending: file,
				},
			};
			this.$refs['stateRuleForm'].validate(async (valid) => {
				if (valid) {
					const res = await schemeApprovalLinkChange(
						stateObj[this.params.nextApprovalLinkName]
					);
					if (res.returncode == 0) {
						this.$message({
							message: '扭转成功',
							type: 'success',
						});
						this.getReadSchemeApprovalInfo();
						const data = stateObj[this.params.nextApprovalLinkName];
						data.nextApprovalLinkName =
							this.indexList[this.index + 2] || '';
						data.nextApprovalLink =
							this.indexNum[this.index + 2] || 0;
						this.$emit('success', data);
						this.ruleForm = this.$options.data().ruleForm;
						this.$refs['stateRuleForm'].resetFields();
					}
				}
			});
		},
		async getReadSchemeApprovalInfo() {
			const res = await readSchemeApprovalInfo({
				schemeApprovalId: this.params.schemeApprovalId,
			});
			this.index = res.schemeApprovalInfoVO.schemeImplementCondition
				.split(',')
				.findIndex(
					(item) =>
						item == res.schemeApprovalInfoVO.currentApprovalLink
				);
			this.indexNum =
				res.schemeApprovalInfoVO.schemeImplementCondition.split(',');
			this.indexList =
				res.schemeApprovalInfoVO.schemeImplementConditionName.split(
					','
				);
			if (res.schemeApprovalInfoVO.attachmentAgreement) {
				res.schemeApprovalInfoVO.attachmentAgreement = JSON.parse(
					res.schemeApprovalInfoVO.attachmentAgreement
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				res.schemeApprovalInfoVO.attachmentAgreement = [];
			}

			if (res.schemeApprovalInfoVO.attachmentBridgeBusiness) {
				res.schemeApprovalInfoVO.attachmentBridgeBusiness = JSON.parse(
					res.schemeApprovalInfoVO.attachmentBridgeBusiness
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				res.schemeApprovalInfoVO.attachmentBridgeBusiness = [];
			}
			if (res.schemeApprovalInfoVO.attachmentRedemptionBusiness) {
				res.schemeApprovalInfoVO.attachmentRedemptionBusiness =
					JSON.parse(
						res.schemeApprovalInfoVO.attachmentRedemptionBusiness
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
			} else {
				res.schemeApprovalInfoVO.attachmentRedemptionBusiness = [];
			}
			if (res.schemeApprovalInfoVO.attachmentMortgageRegistration) {
				res.schemeApprovalInfoVO.attachmentMortgageRegistration =
					JSON.parse(
						res.schemeApprovalInfoVO.attachmentMortgageRegistration
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
			} else {
				res.schemeApprovalInfoVO.attachmentMortgageRegistration = [];
			}
			if (res.schemeApprovalInfoVO.attachmentPledgeRegistration) {
				res.schemeApprovalInfoVO.attachmentPledgeRegistration =
					JSON.parse(
						res.schemeApprovalInfoVO.attachmentPledgeRegistration
					).map((item) => ({
						name: item.fileName,
						url: item.filePath,
					}));
			} else {
				res.schemeApprovalInfoVO.attachmentPledgeRegistration = [];
			}
			if (res.schemeApprovalInfoVO.attachmentOthers) {
				res.schemeApprovalInfoVO.attachmentOthers = JSON.parse(
					res.schemeApprovalInfoVO.attachmentOthers
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				res.schemeApprovalInfoVO.attachmentOthers = [];
			}
			if (res.schemeApprovalInfoVO.attachmentBankLending) {
				res.schemeApprovalInfoVO.attachmentBankLending = JSON.parse(
					res.schemeApprovalInfoVO.attachmentBankLending
				).map((item) => ({
					name: item.fileName,
					url: item.filePath,
				}));
			} else {
				res.schemeApprovalInfoVO.attachmentBankLending = [];
			}
			const stateObj = {
				授信及担保协议: {
					name: '授信及担保协议',
					startTime: res.schemeApprovalInfoVO.startTimeAgreement,
					endTime: res.schemeApprovalInfoVO.endTimeAgreement,
					remark: res.schemeApprovalInfoVO.remarkAgreement,
					file: res.schemeApprovalInfoVO.attachmentAgreement,
				},
				过桥业务: {
					name: '过桥业务',
					startTime: res.schemeApprovalInfoVO.startTimeBridgeBusiness,
					endTime: res.schemeApprovalInfoVO.endTimeBridgeBusiness,
					remark: res.schemeApprovalInfoVO.remarkBridgeBusiness,
					file: res.schemeApprovalInfoVO.attachmentBridgeBusiness,
				},
				赎楼业务: {
					name: '赎楼业务',
					startTime:
						res.schemeApprovalInfoVO.startTimeRedemptionBusiness,
					endTime: res.schemeApprovalInfoVO.endTimeRedemptionBusiness,
					remark: res.schemeApprovalInfoVO.remarkRedemptionBusiness,
					file: res.schemeApprovalInfoVO.attachmentRedemptionBusiness,
				},
				抵押登记: {
					name: '抵押登记',
					startTime:
						res.schemeApprovalInfoVO.startTimeMortgageRegistration,
					endTime:
						res.schemeApprovalInfoVO.endTimeMortgageRegistration,
					remark: res.schemeApprovalInfoVO.remarkMortgageRegistration,
					file: res.schemeApprovalInfoVO
						.attachmentMortgageRegistration,
				},
				质押登记: {
					name: '质押登记',
					startTime:
						res.schemeApprovalInfoVO.startTimePledgeRegistration,
					endTime: res.schemeApprovalInfoVO.endTimePledgeRegistration,
					remark: res.schemeApprovalInfoVO.remarkPledgeRegistration,
					file: res.schemeApprovalInfoVO.attachmentPledgeRegistration,
				},
				其他业务: {
					name: '其他业务',
					startTime: res.schemeApprovalInfoVO.startTimeOthers,
					endTime: res.schemeApprovalInfoVO.endTimeOthers,
					remark: res.schemeApprovalInfoVO.remarkOthers,
					file: res.schemeApprovalInfoVO.attachmentOthers,
				},
				银行放款: {
					name: '银行放款',
					startTime: res.schemeApprovalInfoVO.loanTimeBankLending,
					money: res.schemeApprovalInfoVO.loanAmountBankLending,
					remark: res.schemeApprovalInfoVO.remarkBankLending,
					file: res.schemeApprovalInfoVO.attachmentBankLending,
				},
			};
			this.indexListObj = [];
			this.indexList.slice(0, this.index + 1).forEach((element) => {
				this.indexListObj.push(stateObj[element]);
			});
		},
		open() {
			this.getReadSchemeApprovalInfo();
		},
		handelOnChange(res) {
			if (res.filePath) {
				res.url = res.filePath
			}
			if (res.url.includes('png') || res.url.includes('jpg') || res.url.includes('bmp') || res.url.includes('jpeg') || res.url.includes('xlsx') || res.url.includes('docx') || res.url.includes('pdf')) {
				let routeData = this.$router.resolve({ path: '/public', query: { url: res.url } });

				window.open(routeData.href, '_blank');
				return;
			}

			location.href =
				this.$baseUrl +
				'api/file/fileDownloadCommon.do?path=' +
				res.url;
		},
	},
	created() { },

	computed: {
		drawer_: {
			get() {
				return this.stateVisible;
			},
			set(v) {
				this.$emit('update:stateVisible', v);
			},
		},
		getStateList() {
			return this.params.schemeImplementCondition
				.split(',')
				.map((item) => this.stateList[item - 1]);
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep .el-upload-list__item {
	transition: none !important;
}

::v-deep .demo-drawer__footer {
	display: flex;
	justify-content: center;
	width: 100%;
	padding-bottom: 20px;

	.el-button {
		width: 15%;
	}
}

::v-deep .read .el-form-item {
	margin-bottom: 0;
}

.sy-card-header {
	height: 42px;
	line-height: 42px;
	border-bottom: 1px solid #f6f6f6;
	font-size: 14px;
	font-weight: bold;
}

.sy-card-header i {
	width: 3px;
	height: 14px;
	background: #5799f0;
	display: inline-block;
	vertical-align: middle;
	margin-top: -2px;
	margin-right: 5px;
}

.read-text {
	padding: 0 120px;
}

.read .read-text>div {
	margin-top: 30px;
}
</style>
